<template>
  <v-container fluid no-gutters>
    <B2cTabs/>

    <v-row justify="center" class="mt-10">
      <v-col cols="4">
        <v-card style="width: 100%; min-width: 300px">
          <v-row>
            <v-col cols="12">
              <h2 class="pa-4">Add Menu Item</h2>
            </v-col>
            <v-divider></v-divider>
            <v-col cols="12">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate
                    ><h3>Pages</h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-row
                      v-for="(page, index) in pages"
                      :key="index"
                      @click="selectPage(page.id)"
                    >
                      <v-col cols="12">
                        <v-row>
                          <v-col cols="1">
                            <v-checkbox
                              v-model="page.selected"
                              hide-details
                              class="my-0 py-0"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="8">
                            <h4>{{ page.link_text }}</h4>
                          </v-col>
                          <v-col cols="1">
                            <v-icon @click="toggleChildren(index)">
                              {{
                                page.showChildren
                                  ? "mdi-chevron-up"
                                  : "mdi-chevron-down"
                              }}
                            </v-icon>
                          </v-col>
                          <v-col cols="2"></v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12" v-if="page.showChildren">
                        <v-row
                          v-for="(child, childIndex) in page.children"
                          :key="childIndex"
                          link
                        >
                          <v-col cols="1"></v-col>
                          <v-col cols="1">
                            <v-checkbox
                              v-model="child.selected"
                              hide-details
                              class="my-0 py-0"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="10">
                            {{ child.link_text }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="pt-0 pb-0">
                        <v-btn
                          text
                          outlined
                          class="white--text ma-1 blue-color"
                          @click="addToMenu('page')"
                          size="x-small"
                          >Add to Menu
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header disable-icon-rotate
                    ><h3>Custom Links</h3></v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-row>
                        <v-col cols="12" class="pt-0 pb-0">
                          <v-text-field
                            label="URL"
                            outlined
                            v-model="customLink.url"
                            class="my-0"
                            placeholder="#"
                            :rules="[(v) => !!v || 'URL is required']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          :cols="is_arabic_enabled ? 6 : 12"
                          class="pt-0 pb-0"
                        >
                          <v-text-field
                            label="Link Text"
                            outlined
                            v-model="customLink.link_text"
                            class="my-0"
                            :rules="[(v) => !!v || 'Link Text is required']"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col
                          cols="6"
                          class="pt-0 pb-0"
                          v-if="is_arabic_enabled"
                        >
                          <v-text-field
                            label="Link Text (AR)"
                            outlined
                            v-model="customLink.ar_link_text"
                            class="my-0"
                            dir="rtl"
                            :rules="[
                              (v) => !!v || 'Link Text (Ar) is required',
                            ]"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="6" class="pt-0 pb-0">
                          <v-btn
                            text
                            outlined
                            class="white--text ma-1 blue-color"
                            @click="addToMenu('custom')"
                            size="x-small"
                            >Add to Menu
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card style="width: 100%; min-width: 300px">
          <v-row>
            <v-col cols="12">
              <h2 class="pa-4">Menu Structure</h2>
            </v-col>
            <v-col cols="12">
              <v-expansion-panels v-model="panel" multiple>
                <v-expansion-panel
                  v-for="(hm, index) in menu"
                  :key="index"
                  expand
                >
                  <v-expansion-panel-header
                    disable-icon-rotate
                    class="parent-border"
                  >
                    <h3>
                      {{ hm.link_text }} <sub>({{ hm.path }})</sub>
                    </h3>
                    <template v-slot:actions>
                      <v-btn
                        class="ma-1"
                        @click="removeMenuItem(index)"
                        fab
                        x-small
                        title="Remove Menu Item"
                      >
                        <v-icon color="red">mdi-delete-outline</v-icon>
                      </v-btn>
                      <v-btn
                        class="ma-1"
                        @click="itemUp(index)"
                        fab
                        x-small
                        title="Up Menu Item"
                        dark
                      >
                        <v-icon color="white">mdi-chevron-up</v-icon>
                      </v-btn>
                      <v-btn
                        class="ma-1"
                        @click="itemDown(index)"
                        fab
                        x-small
                        title="Down Menu Item"
                        color="teal"
                      >
                        <v-icon color="white">mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content
                    v-if="hm.children && hm.children.length > 0"
                  >
                    <v-row
                      v-for="(child, cindex) in hm.children"
                      :key="`c_${cindex}`"
                    >
                      <v-col cols="12" class="child-border">
                        <v-row class="mt-2 py-0">
                          <v-col cols="9" class="py-0">
                            <h4 class="ml-4">
                              <span class="mdi mdi-arrow-right"></span>
                              {{ child.link_text }}
                              <sub>({{ child.path }})</sub>
                            </h4>
                          </v-col>
                          <v-col cols="3" class="py-0 text-right">
                            <v-btn
                              @click="removeChildMenuItem(index, cindex)"
                              fab
                              x-small
                              title="Remove Child Menu Item"
                            >
                              <v-icon color="red">mdi-delete-outline</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="2"></v-col>
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn class="ma-2 white--text teal-color" to="/b2c/pages">Back</v-btn>
      <v-btn class="ma-2 white--text blue-color" @click="submitData"
        >Save Menu</v-btn
      >
    </v-row>
    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import B2cTabs from "@/views/B2CConfigurations/B2cTabs.vue";

export default {
  components: {B2cTabs},
  mounted() {
    this.btnShow = true;
    if (this.$route.query && this.$route.query.type) {
      this.type = this.$route.query.type;
      this.getMenuData(this.type);
    } else {
      this.getMenuData();
    }
  },
  data() {
    return {
      valid: true,
      type: null,
      /*
        pages: [
            {
                id: 1,
                link_text: "About Us",
                ar_link_text: "",
                path: "about-us",
                is_custom: false,
                position: 1,
                selected: false,
                showChildren:false,
                children: [
                    {
                        page_id: null,
                        collaps_id: 1,
                        link_text: "Origin",
                        ar_link_text: "",
                        path: "origin",
                        is_custom: false,
                        position: 1,
                        selected: false
                    },
                    {
                        page_id: null,
                        collaps_id: 2,
                        link_text: "Vision Mission & Value",
                        ar_link_text: "",
                        path: "vision",
                        is_custom: false,
                        position: 2,
                        selected: false
                    },
                    {
                        page_id: null,
                        collaps_id: 3,
                        link_text: "Architecture",
                        ar_link_text: "",
                        path: "#Architecture",
                        is_custom: false,
                        position: 3,
                        selected: false
                    },
                    {
                        page_id: null,
                        collaps_id: 4,
                        link_text: "Contact Us",
                        ar_link_text: "",
                        path: "#contact-us",
                        is_custom: false,
                        position: 4,
                        selected: false
                    },
                ]
            },
        ],*/
      pages: [],
      menu: [],
      headerTab: {
        page_id: null,
        link_text: "",
        is_default: 0,
        ar_link_text: "",
        path: "",
        is_custom: false,
        position: 1,
        children: [
          {
            page_id: null,
            collapse_id: null,
            is_default: 0,
            link_text: "",
            ar_link_text: "",
            path: "",
            is_custom: false,
            position: 1,
            type: "",
          },
        ],
      },
      selectedPage: null,
      selectedCollaps: null,
      pageId: null,
      confirmModel: {
        id: null,
        childId: null,
        title: null,
        description: null,
      },
      customLink: {
        url: "",
        link_text: "",
        ar_link_text: "",
      },
      qube_configuration: "generic",
      is_arabic_enabled: false,
      panel: [0],
    };
  },
  computed: {
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
  },

  watch: {
    // pageData: {
    //     handler: function (newData) {
    //         console.log(newData);
    //     },
    //   immediate: true,
    //   // deep: true,
    // },
  },

  methods: {
    getMenuData() {
      this.showLoader("Loading");
      let type = this.type ? this.type : 0;
      this.$http
        .get(`venues/b2c/configurations/menu?type=` + type)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.hideLoader();
            if (response.data.data) {
              const data = response.data.data;
              if (data.configuration) {
                this.menu = [];
                this.is_arabic_enabled = data.configuration.enable_arabic;
                this.qube_configuration = data.configuration.qube_configuration;
                if (data.configuration.header_nav && this.type == "header") {
                  this.menu = JSON.parse(data.configuration.header_nav);
                } else if (
                  data.configuration.footer_nav &&
                  this.type == "footer"
                ) {
                  this.menu = JSON.parse(data.configuration.footer_nav);
                }
              }
              this.pages = data.pages;
              this.$forceUpdate();
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.hideLoader();
        });
    },

    toggleChildren(index) {
      this.pages[index].showChildren = !this.pages[index].showChildren;
    },
    selectPage(pageId) {
      this.selectedPage = this.pages.find((page) => page.id === pageId);
    },
    addToMenu(type = null) {
      if (type == "custom") {
        if (!this.$refs.form.validate()) {
          this.showError("Please fill all required fields");
          return;
        }
        this.showLoader("Adding");
        console.log(this.headerTab);
        const headerTabObj = Object.assign({}, this.headerTab);
        headerTabObj.path = this.customLink.url;
        headerTabObj.is_default = 0;
        headerTabObj.link_text = this.customLink.link_text;
        headerTabObj.ar_link_text = this.customLink.ar_link_text
          ? this.customLink.ar_link_text
          : null;
        headerTabObj.is_custom = true;
        headerTabObj.page_id = null;
        headerTabObj.position = this.menu.length + 1;
        headerTabObj.children = [];

        this.menu.push(headerTabObj);
        this.customLink = {
          url: "",
          link_text: "",
          ar_link_text: "",
        };
        console.log("custom");
        this.hideLoader();
        this.$refs.form.reset();
      } else if (type == "page") {
        console.log("page");
        this.filterItemAddToMenu();
      }
      this.panel = Array.from(Array(this.menu.length).keys());
      console.log("header menu");
      console.log(this.menu);
    },
    filterItemAddToMenu() {
      const allPages = Object.assign([], this.pages);
      console.log("all pages", allPages);
      let newPosition = this.menu.length;
      allPages.forEach((page) => {
        if (page.selected) {
          let findIndex = -1;
          if (this.menu.length) {
            if (page.id) {
              findIndex = this.menu.findIndex(
                (item) => parseInt(item.page_id) === parseInt(page.id)
              );
            }
          }
          if (findIndex !== -1) {
            /** check children if exist then updated otherwise add */
            if (page.children.length > 0) {
              page.children.forEach((child) => {
                if (child.selected) {
                  let findChildIndex = -1;
                  let newChildPosition;
                  if (
                    this.menu[findIndex].children &&
                    this.menu[findIndex].children.length
                  ) {
                    newChildPosition = this.menu[findIndex].children.length;
                  } else {
                    newChildPosition = 0;
                    this.menu[findIndex]["children"] = [];
                  }

                  /** check child item page or collapse */
                  if (child.type == "collapse") {
                    findChildIndex = this.menu[findIndex].children.findIndex(
                      (childItem) =>
                        parseInt(childItem.collapse_id) ===
                        parseInt(child.collapse_id)
                    );
                  } else if (child.type == "page") {
                    findChildIndex = this.menu[findIndex].children.findIndex(
                      (childItem) =>
                        parseInt(childItem.page_id) ===
                          parseInt(child.page_id) && childItem.type == "page"
                    );
                  }

                  if (findChildIndex !== -1) {
                    this.menu[findIndex].children[findChildIndex] = {
                      page_id: child.page_id,
                      collapse_id:
                        child.type == "collapse"
                          ? parseInt(child.collapse_id)
                          : null,
                      link_text: child.link_text,
                      ar_link_text: child.ar_link_text,
                      path: child.path,
                      is_custom: false,
                      position:
                        this.menu[findIndex].children[findChildIndex].position,
                      type: child.type,
                    };
                  } else {
                    let childObj = {
                      page_id: child.page_id,
                      collapse_id:
                        child.type == "collapse"
                          ? parseInt(child.collapse_id)
                          : null,
                      link_text: child.link_text,
                      ar_link_text: child.ar_link_text,
                      path: child.path,
                      is_custom: false,
                      position: newChildPosition + 1,
                      type: child.type,
                    };
                    this.menu[findIndex].children.push(childObj);
                  }
                }
              });
            } else {
              this.menu[findIndex] = {
                page_id: parseInt(page.id),
                path: page.path,
                is_default: page.is_default,
                link_text: page.link_text,
                ar_link_text: page.ar_link_text,
                is_custom: false,
                position: this.menu[findIndex].position,
                children: [],
                type: "page",
              };
            }
          } else {
            newPosition++;
            let obj = {
              page_id: parseInt(page.id),
              path: page.path,
              link_text: page.link_text,
              ar_link_text: page.ar_link_text,
              is_default: page.is_default,
              is_custom: false,
              position: newPosition,
              children: [],
              type: "page",
            };
            if (page.children && page.children.length > 0) {
              let childPos = 0;
              page.children.forEach((child) => {
                if (child.selected) {
                  childPos++;
                  let childObj = {
                    page_id: child.page_id,
                    path: child.path,
                    collapse_id:
                      child.type == "collapse"
                        ? parseInt(child.collapse_id)
                        : null,
                    link_text: child.link_text,
                    ar_link_text: child.ar_link_text,
                    is_default: 0,
                    position: childPos,
                    is_custom: false,
                    type: child.type,
                  };
                  obj.children.push(childObj);
                }
              });
            }
            this.menu.push(obj);
          }
        }
      });
    },
    removeMenuItem(index) {
      this.confirmModel = {
        id: index,
        title: "Do you want to remove this item?",
        description:
          "By clicking <b>Yes</b> you can confirm the operation.  Do you need to continue your action ?",
        type: "removeMenuItem",
      };
    },
    removeChildMenuItem(index, childIndex) {
      this.menu[index].children.splice(childIndex, 1);
      if (this.menu[index].children.length) {
        this.resetChildMenuPosition(index);
      }
    },
    confirmActions(data) {
      if (data.type == "removeMenuItem") {
        this.menu.splice(data.id, 1);
        this.resetMenuPosition();
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    resetMenuPosition() {
      if (this.menu.length > 0) {
        this.menu.forEach((page, index) => {
          this.menu[index].position = index + 1;
        });
      }
    },
    resetChildMenuPosition(index) {
      if (this.menu[index].children.length > 0) {
        this.menu[index].children.forEach((child, cindex) => {
          this.menu[index].children[cindex].position = cindex + 1;
        });
      }
      console.log("remove child menue item");
      console.log(this.menu);
    },
    itemUp(itemIndex) {
      if (
        itemIndex > 0 &&
        this.menu[itemIndex - 1] &&
        this.menu[itemIndex - 1].position >= 0
      ) {
        let currentPosition = this.menu[itemIndex].position;
        let prevPosition = this.menu[itemIndex - 1].position;
        this.menu[itemIndex].position = prevPosition;
        this.menu[itemIndex - 1].position = currentPosition;
      }
      this.menu.sort((a, b) => a.position - b.position);
    },
    itemDown(itemIndex) {
      if (
        itemIndex < this.menu.length &&
        this.menu[itemIndex + 1] &&
        this.menu[itemIndex + 1].position
      ) {
        let currentPosition = this.menu[itemIndex].position;
        let nextPosition = this.menu[itemIndex + 1].position;
        this.menu[itemIndex].position = nextPosition;
        this.menu[itemIndex + 1].position = currentPosition;
        this.menu.sort((a, b) => a.position - b.position);
      }
    },
    submitData() {
      this.showLoader("Saving...");
      // let formData = new FormData();
      let validate_data = true;
      let data = { menu: [], type: null };
      data.type = this.type;

      // formData.append(`type`, this.type);
      this.menu.forEach((page, index) => {
        data.menu[index] = page;
        if (page.path) {
          // page.path = page.path.replace(/\s/g, "");
          data.menu[index].path = page.path.replace(/\s/g, "");
        } else {
          validate_data = false;
          this.hideLoader();
          this.showError(
            "Please add a unique path for additional page, ex. about-us, privacy-policy etc"
          );
          return;
        }
        // formData.append(`menu[${index}][page_id]`, (page.page_id == 'null' || page.page_id === null)?"":page.page_id);
        // formData.append(`menu[${index}][path]`, page.path);
        // formData.append(`menu[${index}][is_default]`, page.is_default);
        // formData.append(`menu[${index}][link_text]`, page.link_text);
        // formData.append(`menu[${index}][ar_link_text]`, page.ar_link_text);
        // formData.append(`menu[${index}][is_custom]`, page.is_custom);
        // formData.append(`menu[${index}][position]`, page.position);

        // if (page.children && page.children.length > 0) {
        //     page.children.forEach((child,cIndex) => {
        //         for (const key in child) {
        //             const value = child[key];
        //             formData.append(`menu[${index}][children][${cIndex}][${key}]`, (value== 'null' || value === null)?"":value);
        //         }
        //     })
        // }
      });
      if (!validate_data) {
        return;
      }
      this.$http
        .post(`venues/b2c/configurations/menu`, data)
        .then((response) => {
          this.hideLoader();
          if (response.status == 200 && response.data.status == true) {
            this.showSuccess("Menu saved successfully.");
            this.getMenuData();
          }
        })
        .catch((error) => {
          this.hideLoader();
          this.errorChecker(error);
        });
    },
  },
};
</script>

<style scoped>
.salesBtn {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  color: #066a8c;
}

.btn_bg {
  background-color: #fff;
  border-bottom: 3px solid #066a8c;
  border-radius: 10px;
  box-shadow: 0 3px 3px -2px black;
}

.upload-btn-main {
  background-color: #f4f8fa;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px dashed #d0d0d0;
  width: 190px;
  height: 80px;
}

.upload-btn {
  background-color: #e5f0f6 !important;
  color: #16b4b3 !important;
  font-weight: 600;
  font-size: 13px;
  width: 135px;
  text-transform: none;
}

.img-main {
  position: relative;
  border-radius: 8px;
  height: 200px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 20px;
}

.img-main:hover .remove-img-btn {
  display: block;
}

.remove-img-btn {
  display: none;
  background-color: #c62828 !important;
  border-radius: 50% !important;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: -5px;
}

.add-new-carousel-btn {
  font-weight: 600;
  background-color: #052b48 !important;
  color: #fff !important;
}

.remove-carousel-btn {
  color: #f44336 !important;
  text-transform: none;
  background-color: #ffeaea !important;
}

.carousel-subtitle {
  font-weight: 400;
  color: #adaaaa;
  letter-spacing: 1px;
}

.add-new-partner-btn {
  background-color: #00b1af !important;
  color: #fff !important;
  font-weight: 600;
  font-size: 13px;
  letter-spacing: 1px;
}

.partners-main {
  max-width: 600px;
  width: 100%;
  display: flex;
}

.partner-thumb {
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.partner-thumb-gray {
  filter: grayscale(1);
  opacity: 0.4;
}

.partner-popup-logo-main {
  background-color: #fff;
  border-radius: 5px;
  font-size: 12px;
  letter-spacing: 1px;
}

.partner-erorr-note {
  color: #e53935;
}
</style>
